import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "@layout/Container/Container"
import SEO from "@utilities/SEO"
import PostCard from "@elements/PostCard/PostCard"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
        <Container wrap>
          { posts.map(({ node }) => {
            const { path, title, date, image } = node.frontmatter
            return (
                <PostCard
                  key={ title }
                  path={ path }
                  title={ title }
                  date={ date }
                  image={ image }
                />
                )
          })}
        </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            image
          }
        }
      }
    }
  }
`